<template>
  <b-modal
    v-model="visible"
    cancel-variant="outline-secondary"
    :ok-title="$t('Сохранить')"
    no-close-on-backdrop
    :cancel-title="$t('Отмена')"
    centered
    size="lg"
    :title="title"
    @ok.prevent="save"
  >
    <validation-observer
      ref="form"
      #default="{invalid}"
    >
      <b-form
        class="auth-login-form mt-2"
      >
        <div class="row">
          <div class="col-6">
            <!-- <b-form-group
              :label="$t('Выберите категорию')"
              label-for="category_id"
            >
              <validation-provider
                #default="{ errors }"
                name="category_id"
                rules="required"
              >
                <b-form-group>
                  <v-select
                    v-model="selected"
                    label="title"
                    :options="treeNodes"
                  />
                </b-form-group>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group> -->
            <b-form-group
              :label="$t('Выберите категорию')"
              label-for="category_id"
            >
              <!-- <validation-provider
              #default="{ errors }"
              name="category_id"
              rules="required"
            > -->
              <v-tree
                v-if="treeNodes && treeNodes.length"
                id="category_id"
                :data="treeNodes"
                searchable
                :multiple="false"
                radio
                @node-select="select"
              />
              <div v-else>
                <div class="empty text-muted w-100 text-center my-2">
                  {{ $t('В таблице отсутствуют данные') }}
                </div>
              </div>
            <!-- <small class="text-danger">{{ errors[0] }}</small> -->
            <!-- </validation-provider> -->
            </b-form-group>
          </div>
          <div class="col-6">
            <b-form-group
              :label="$t('Именование')"
              label-for="name_uz"
            >
              <validation-provider
                #default="{ errors }"
                name="name_uz"
                rules="required"
              >
                <b-form-input
                  id="name_uz"
                  v-model="form.name_uz"
                  :state="errors.length > 0 ? false:null"
                  name="name_uz"
                  placeholder=""
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>

            <b-form-group
              :label="$t('Цена(сум)')"
              label-for="price"
            >
              <validation-provider
                #default="{ errors }"
                name="price"
                rules="required"
              >
                <cleave
                  id="price"
                  v-model="form.price"
                  class="form-control"
                  :options="cleaveOption"
                  placeholder="10,000"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </div>
        </div>

      </b-form>
    </validation-observer>
  </b-modal>
</template>

<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import VTree from 'vue-tree-halower'
import { showToast } from '@/utils/toast'
import { required } from '@validations'
import { mapActions, mapGetters } from 'vuex'
import { clearObject } from '@/utils'
import Cleave from 'vue-cleave-component'
import vSelect from 'vue-select'
import 'vue-tree-halower/dist/halower-tree.min.css'
// eslint-disable-next-line import/no-extraneous-dependencies
import 'cleave.js/dist/addons/cleave-phone.us'

export default {
  name: 'Create',
  components: {
    ValidationProvider, ValidationObserver, VTree, Cleave, vSelect,
  },
  props: {
    tabid: {
      type: Number,
      default: () => 1,
    },
  },
  data() {
    return {
      form: {
        id: null,
        category_id: null,
        name_uz: null,
        name_ru: null,
        price: null,
      },
      cleaveOption: { numeral: true, numeralThousandsGroupStyle: 'thousand' },
      loading: false,
      selected: null,
      title: '',
      visible: false,
      required,
    }
  },
  computed: {
    ...mapGetters({ treeNodes: 'productCategory/GET_TREE' }),
  },
  watch: {
    visible(newVal) {
      if (!newVal) setTimeout(() => { clearObject(this.form) }, 200)
    },
  },
  mounted() {
    this.getItems()
    this.title = 'Tovar qo`shish'
    this.$root.$on('add', data => {
      this.visible = true
    })
  },
  methods: {
    async save() {
      const valid = await this.validationForm()
      if (valid) {
        this.method(this.form).then(res => {
          showToast('success', this.$t('Успешно сохранено'), 'CheckCircleIcon')
          this.$emit('onSuccess')
          this.visible = false
        }).catch(err => {
          if (err.status === 422) {
            this.$refs.form.setErrors(err.data.errors)
          }
          showToast('danger', this.$t('Ошибка'), 'XIcon')
        })
      } else {
        showToast('warning', this.$t('Заполните необходимые поля'))
      }
    },
    select(item, isSelect) {
      this.form.category_id = item.id
      this.selected = isSelect ? item : null
    },
    edit(item) {
      this.form.id = item.id
      this.form.name_uz = item.name_uz
      this.form.name_ru = item.name_ru
      this.visible = true
    },
    method(data) {
      if (this.form.id) return this.update(data)
      return this.store(data)
    },
    async validationForm() {
      let validated = false
      await this.$refs.form.validate().then(success => {
        validated = success
      })
      return validated
    },
    async getItems() {
      this.selected = null
      this.loading = true
      await this.getItemsAction()
      this.loading = false
    },
    ...mapActions({
      store: 'product/store', update: 'product/update', getItemsAction: 'productCategory/tree',
    }),
  },
}
</script>

<style scoped>

</style>
